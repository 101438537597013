.icon-margin{
    margin: 0px 4px;
}


.App{
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}


.hr{
    color: red;
    margin: 0rem 0.25rem;
}
